exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-contentful-blog-slug-js": () => import("./../../../src/pages/blog/{contentfulBlog.slug}.js" /* webpackChunkName: "component---src-pages-blog-contentful-blog-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-contentful-news-slug-js": () => import("./../../../src/pages/news/{contentfulNews.slug}.js" /* webpackChunkName: "component---src-pages-news-contentful-news-slug-js" */),
  "component---src-pages-recruit-js": () => import("./../../../src/pages/recruit.js" /* webpackChunkName: "component---src-pages-recruit-js" */),
  "component---src-templates-blog-index-template-js": () => import("./../../../src/templates/blog-index-template.js" /* webpackChunkName: "component---src-templates-blog-index-template-js" */),
  "component---src-templates-news-index-template-js": () => import("./../../../src/templates/news-index-template.js" /* webpackChunkName: "component---src-templates-news-index-template-js" */)
}

